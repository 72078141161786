import React, { useEffect, useState, useCallback } from "react";
import "./main.scss";
import styles from "./award.module.scss";
import client from "../client";
import { PortableText } from "@portabletext/react";
import ExternalLinkButton from "../components/ui/ExternalLinkButton";
import portableTextComponents from "../config/portableTextComponents";
import ImageLoader from "../components/content/ImageLoader";

const Award = () => {
  const [pageData, setPageData] = useState(null);

  const fetchPageData = useCallback(async () => {
    const query = `    
    *[_id == "award"][0]{
      ...,
        awards[] {
          ...,
          image {
            ...,
            asset-> {
            url,
            metadata 
          }
        }
      }
    }`;
    client
      .fetch(query)
      .then((data) => {
        console.log(data);
        setPageData(data);
      })
      .catch(console.error);
  }, [setPageData]);

  useEffect(() => {
    if (!pageData) {
      fetchPageData();
    }
  }, [pageData, fetchPageData]);

  if (pageData) {
    return (
      <main className={`main ${styles.main}`}>
        <div className={styles.intro}>
          <PortableText
            value={pageData.intro}
            components={portableTextComponents}
          />
        </div>

        <div className={styles.main_content}>
          <div className={styles.hero_award_container}>
            <div className={styles.hero_award_text}>
              <h3>{`${pageData.awards[0].year} AWARD`}</h3>
              <h1>{pageData.awards[0].title}</h1>
              <p
                className={styles.hero_author_name}
              >{`By ${pageData.awards[0].author}`}</p>
              <PortableText
                value={pageData.awards[0].description}
                components={portableTextComponents}
              />
              {
                pageData.awards[0].link ? 
                <ExternalLinkButton
                link={pageData.awards[0].link}
                target="_blank"
                rel="noreferrer"
                text="Read the full paper"
                alwaysHighlight
              /> : ''
              }
              
            </div>
            <div className={styles.hero_award_image}>
            {pageData.awards[0].image ?
              <ImageLoader
                image={pageData.awards[0].image}
                width={800}
                height={600}
              /> : ''}
            </div>
          </div>

          <div className={styles.award_tiles}>
            {pageData.awards.map((award, index) => {
              if (index > 0) {
                return (
                  <div className={styles.award_tile} key={index}>
                    <ImageLoader image={award.image} width={500} height={500} />
                    <h3>{`${award.year} AWARD`}</h3>
                    <h2>{award.title}</h2>
                    <div className={styles.award_byline}>
                      <p>{`Paper by ${award.author}`}</p>
                      {
                          award.link ? 
                          <span>
                          <ExternalLinkButton
                            link={award.link}
                            text="Read here"
                            alwaysHighlight
                          />
                        </span>
                        : ''
                      }
                     
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </main>
    );
  }

  return null;
};

export default Award;
