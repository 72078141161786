import React, { useEffect, useState, useCallback, useRef } from "react";
import "./main.scss";
import styles from "./board.module.scss";
import client from "../client";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../config/portableTextComponents";
import { Route, Routes } from "react-router-dom";
import LinkButton from "../components/ui/LinkButton";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import ImageLoader from "../components/content/ImageLoader";
import { Fragment } from "react";
import FunctionButton from "../components/ui/FunctionButton";
import useWindowSize from "../hooks/useWindowSize";

const Board = () => {
  const [pageData, setPageData] = useState(null);
  const location = useLocation();
  const title = useRef();
  const size = useWindowSize();

  const fetchPageData = useCallback(async () => {
    const query = `    
      *[_id == "board"][0] {
        ...,
        members[] {
          ...,
          image {
            ...,
            asset-> {
              url,
              metadata 
            }
          }
        }
      }`;
    client
      .fetch(query)
      .then((data) => {
        console.log(data);
        setPageData(data);
      })
      .catch(console.error);
  }, [setPageData]);

  useEffect(() => {
    if (!pageData) {
      fetchPageData();
    }
  }, [pageData, fetchPageData]);

  const scrollToNames = () => {
    if (title.current) {
      title.current.scrollIntoView({ behavior: "smooth"});
    }
  }

  if (pageData) {
    return (
      <main className={`main ${styles.main}`}>
        <h3 ref={title}>OUR BOARD</h3>

        <div className={styles.content}>
          <section className={styles.board_person_list_section}>
            <div className={styles.board_person_list} >
              {pageData.members.map((member, index) => (
                <div className={styles.board_person_list_name}>
                  <LinkButton
                    link={member.slug.current}
                    forceActive={index === 0 && location.pathname === "/board"}
                    key={index}
                    text={member.name}
                  />
                </div>
              ))}
            </div>
          </section>

          <section className={styles.board_person_details}>
            <AnimatePresence exitBeforeEnter inital={false}>
              <Routes location={location} key={location.pathname}>
                <Route
                  index
                  element={
                    <Fragment>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.board_person_details_text}
                      >
                        <PortableText
                          value={pageData.members[0].bio}
                          components={portableTextComponents}
                        />
                      </motion.div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.board_person_details_image}
                      >
                        <ImageLoader
                          image={pageData.members[0].image}
                          width={400}
                          height={400}
                        />
                      </motion.div>
                    </Fragment>
                  }
                />
                {pageData.members.map((member, index) => (
                  <Route
                    path={member.slug.current}
                    key={index}
                    element={
                      <Fragment>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, transition: { delay: 0.3 } }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className={styles.board_person_details_text}
                        >
                          <PortableText
                            value={member.bio}
                            components={portableTextComponents}
                          />
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, transition: { delay: 0.3 } }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className={styles.board_person_details_image}
                        >
                          <ImageLoader
                            image={member.image}
                            width={400}
                            height={400}
                          />
                        </motion.div>
                      </Fragment>
                    }
                  />
                ))}
              </Routes>
            </AnimatePresence>
          </section>
        </div>
        {
          size.width <= 834 ?
          <FunctionButton text="Back to top" active={true} clickFunction={scrollToNames}/>
          : ""
        }
      </main>
    );
  }

  return null;
};

export default Board;
