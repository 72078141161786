import React from "react";
import styles from "./button.module.scss";
import classNames from "classnames";

const ExternalLinkButton = (props) => {
  const { link, text, clickFunction, isActive, alwaysHighlight, isCompact, forceNoBlank } =
    props;

  return (
    <a
      href={link}
      target={!forceNoBlank ? "_blank" : ""}
      rel="noreferrer"
      className={classNames(styles.main, styles.external_link_button, {
        [styles.active]: isActive,
        [styles.always_highlight]: alwaysHighlight,
        [styles.compact]: isCompact,
      })}
      onClick={clickFunction ? clickFunction : null}
    >
      <div>{text}</div>
      <div className={styles.underline}></div>
    </a>
  );
};

export default ExternalLinkButton;
