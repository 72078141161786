import React from "react";
import styles from "./event-listing.module.scss";
import { PortableText } from "@portabletext/react";
import ExternalLinkButton from "../ui/ExternalLinkButton";
import portableTextComponents from "../../config/portableTextComponents";
import { AnimatePresence, motion } from "framer-motion";
import useWindowSize from "../../hooks/useWindowSize";

const EventListing = (props) => {
  const { event, filter } = props;
  const size = useWindowSize();

  const toTitleCase = (string) => {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const typeTitle = toTitleCase(event.type);

  return (
    <AnimatePresence>
      {(filter === event.type || filter === "all") && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.main}
        >
          <div className={styles.event_listing_date}>{event.date}</div>
          <div className={styles.event_listing_type}>{typeTitle}</div>
          <div className={styles.event_listing_title}>{event.title}</div>
          <div className={styles.event_listing_location}>{event.location}</div>
          <div className={styles.event_listing_description}>
            <PortableText
              value={event.description}
              components={portableTextComponents}
            />
          </div>
          
          <div className={styles.event_listing_link}>
          { event.link && event.buttonText ?
            <ExternalLinkButton link={event.link} text={event.buttonText} alwaysHighlight={size.width < 834}/>
            : ''}
          </div>
         
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EventListing;
