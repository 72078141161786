import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import "./main.scss";
import styles from "./about.module.scss";
import client from "../client";
import { PortableText } from "@portabletext/react";
import portableTextComponents from "../config/portableTextComponents";
import LinkButton from "../components/ui/LinkButton";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";

const About = () => {
  const [pageData, setPageData] = useState(null);
  const location = useLocation();
  const titles = useRef();
  const size = useWindowSize();

  const fetchPageData = useCallback(async () => {
    const query = `    
  *[_id == "about"][0]`;
    client
      .fetch(query)
      .then((data) => {
        console.log(data);
        setPageData(data);
      })
      .catch(console.error);
  }, [setPageData]);

  useEffect(() => {
    if (!pageData) {
      fetchPageData();
    }
  }, [pageData, fetchPageData]);

  const returnToTop = () => {
    if (titles.current) {
      titles.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (pageData) {
    return (
      <main className={`main ${styles.main}`}>
        <section className={styles.about}>
          <h3>ABOUT US</h3>
          <PortableText
            value={pageData?.aboutUs}
            components={portableTextComponents}
          />
        </section>

        <section className={styles.topics}>
          <h3 ref={titles}>TOPICS</h3>
          <div className={styles.topicTitles} >
            {pageData?.topics.map((topic, index) => (
              <div
                className={styles.topicTitlesLinkButtonContainer}
                key={index}
              >
                <LinkButton
                  link={topic.slug.current}
                  text={topic.title}
                  forceActive={index === 0 && location.pathname === "/about"}
                />
              </div>
            ))}
          </div>

          <AnimatePresence exitBeforeEnter inital={false}>
            <Routes location={location} key={location.pathname}>
              <Route
                index
                element={
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.3 } }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.topicText}
                  >
                    <PortableText
                      value={pageData?.topics[0].body}
                      components={portableTextComponents}
                    />
                    {size.width <= 834 ? (
                      <LinkButton
                        link={`/about/${pageData.topics[1].slug.current}`}
                        text={`Next Topic: ${pageData.topics[1].title} `}
                        clickFunction={returnToTop}
                      />
                    ) : (
                      ""
                    )}
                  </motion.div>
                }
              />
              {pageData?.topics.map((topic, index) => (
                <Route
                  path={topic.slug?.current}
                  key={index}
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { delay: 0.3 } }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className={styles.topicText}
                    >
                      <PortableText
                        value={topic.body}
                        components={portableTextComponents}
                      />
                      {size.width <= 834 ? (
                        <LinkButton
                          link={`/about/${
                            pageData.topics[index + 1]
                              ? pageData.topics[index + 1].slug.current
                              : pageData.topics[0].slug.current
                          }`}
                          text={`Next Topic: ${
                            pageData.topics[index + 1]
                              ? pageData.topics[index + 1].title
                              : pageData.topics[0].title
                          } `}
                          clickFunction={returnToTop}
                        />
                      ) : (
                        ""
                      )}
                    </motion.div>
                  }
                />
              ))}
            </Routes>
          </AnimatePresence>
        </section>
      </main>
    );
  }
  return null;
};

export default About;
