import React, { useState, useEffect, useRef } from "react";
import { BlurhashCanvas } from "react-blurhash";
import styles from "./image-loader.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import usePixelRatio from "../../hooks/usePixelRatio"

const ImageLoader = (props) => {
    const { image, width, height } = props;
    const [loaded, setLoaded] = useState(false);
    const pixelRatio = usePixelRatio();
    const imageRef = useRef();

    const url = `${image?.asset.url}?h=${height}&w=${width}&format=auto&fit=crop&crop=focalpoint&fp-x=${image?.hotspot?.x ? image?.hotspot.x : 0}&fp-y=${image?.hotspot?.y ? image?.hotspot.y : 0}&drp=${pixelRatio}`

    useEffect(() => {

        if (imageRef.current) {
            imageRef.current.src = url;
            imageRef.current.addEventListener('load', () => {setLoaded(true)})
        }
    }, [url]);

    if (image) {
        return (
            <div className={styles.main}>
                <img src={""} className={styles.image} ref={imageRef} alt=""/>
                <AnimatePresence  initial={false}>
                    {!loaded &&
                    <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                        transition={{duration: 0.5, ease: "easeInOut"}}
                        className={styles.canvas}
                        >
                        <BlurhashCanvas hash={image.asset.metadata.blurHash} width={Math.floor(width * 0.2)} height={Math.floor(height * 0.2)} punch={1}/>
                    </motion.div>
                    }
                </AnimatePresence>
            </div>
        );
    }
    return null
    
};

export default ImageLoader;