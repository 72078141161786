import ExternalLinkButton from "../components/ui/ExternalLinkButton";

const portableTextComponents = {
  marks: {
    link: ({ children, value }) => {
      return (
        <ExternalLinkButton
          link={value.url}
          text={children}
          alwaysHighlight
          isCompact
        />
      );
    },
  },
  block: {
    h1: ({ children }) => <h1>{children}</h1>,
    h2: ({ children }) => <h2>{children}</h2>,
    h3: ({ children }) => <h3>{children}</h3>,
    span: ({ children }) => <span>{children}</span>,
    normal: ({ children }) => <p>{children}</p>,
    // TODO
  },
};

export default portableTextComponents;
