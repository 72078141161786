import "./styles/globals.scss";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import client from "./client";
import TopNav from "./components/ui/TopNav";
import Home from "./views/Home";
import About from "./views/About";
import Board from "./views/Board";
import Award from "./views/Award";
import Events from "./views/Events";

function App() {
  const location = useLocation();
  const [siteData, setSiteData] = useState(null);

  const fetchSiteData = useCallback(async () => {
    const query = `    
    *[_id == "siteSettings"][0] {
        ...,
        landingImage {
          ...,
            asset-> {
            url,
            metadata 
          }
        }
      }
    `;
    client
      .fetch(query)
      .then((data) => {
        //console.log(data);
        setSiteData(data);
      })
      .catch(console.error);
  }, [setSiteData]);

  useEffect(() => {
    if (!siteData) {
      fetchSiteData();
    }
  }, [siteData, fetchSiteData]);

  if (siteData) {
    return (
      <Fragment>
        <TopNav location={location} data={siteData.contactData}/>
        <Routes location={location} >
          <Route index element={<Home data={siteData.landingImage}/>} />
          <Route path="about" element={<About />} >
            <Route path=":topic" element={<About />} />
          </Route>
          <Route path="board" element={<Board />} >
            <Route path=":member" element={<Board />} />
          </Route>
          <Route path="events" element={<Events />} >
            <Route path=":filter" element={<Events />} />
          </Route>
          <Route path="phd-award" element={<Award />} />
        </Routes>
      </Fragment>
    );
  } 
  return null;
  
}

export default App;
