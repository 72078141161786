import React from 'react';
import styles from "./button.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";

const BurgerButton = (props) => {
    const { setPlayer, toggleMenu, changeMenuMode, menuMode } = props;

    const handleClick = () => {
        if (menuMode === "main") {
            toggleMenu();
        } else {
            changeMenuMode("main");
        }
    }

    return (
        <div className={styles.burger} onClick={handleClick}>
             <Player
                lottieRef={(instance) => {
                    setPlayer({ lottie: instance });
                }}
                src="https://assets5.lottiefiles.com/packages/lf20_s0xsxpea.json"
                autoplay={false}
                loop={false}
                keepLastFrame={true}
                style={{ height: "100%" }}
                />
        </div>
    );
};

export default BurgerButton;