import React from 'react';
import ImageLoader from '../components/content/ImageLoader';
import "./main.scss";
import useWindowSize from "../hooks/useWindowSize";


const Home = (props) => {
    const {data} = props;
    const size = useWindowSize();

    return (
        <main className={`main`} style={{padding: 0}}>
            <ImageLoader 
                image={data}
                width={size.width}
                height={size.height - 80}
            />
        </main>
    );
    
    

};

export default Home;