import React, { useState, useEffect, useCallback } from "react";
import "./main.scss";
import styles from "./events.module.scss";
import client from "../client";
import { Route, Routes } from "react-router-dom";
import LinkButton from "../components/ui/LinkButton";
import { useLocation } from "react-router-dom";
import EventListing from "../components/content/EventListing";

const Events = () => {
  const [pageData, setPageData] = useState(null);
  const location = useLocation();

  const fetchPageData = useCallback(async () => {
    const query = `*[_id == "events"][0]{
      events[]->
    }`;
    client
      .fetch(query)
      .then((data) => {
        console.log(data);
        setPageData(data);
      })
      .catch(console.error);
  }, [setPageData]);

  useEffect(() => {
    if (!pageData) {
      fetchPageData();
    }
  }, [pageData, fetchPageData]);

  return (
    <main className={`main ${styles.main}`}>
      <div className={styles.filters}>
        <h3 className={styles.filter_item}>FILTER:</h3>
        <div className={styles.filter_item}>
          <LinkButton link="all" text="ALL" forceActive={location.pathname === "/events"}/>
        </div>
        <div className={styles.filter_item}>
          <LinkButton link="conference" text="CONFERENCES" />
        </div>
        <div className={styles.filter_item}>
          <LinkButton link="seminar" text="SEMINARS" />
        </div>
      </div>
      <div className={styles.listings}>
      <Routes location={location} key={location.pathname}>
        <Route
          index
          element={pageData?.events?.map((event, index) => (
            <EventListing event={event} filter="all" key={index} />
          ))}
        />
        <Route
          path={"all"}
          element={pageData?.events?.map((event, index) => (
            <EventListing event={event} filter="all" key={index} />
          ))}
        />
        <Route
          path={"conference"}
          element={pageData?.events?.map((event, index) => (
            <EventListing event={event} filter="conference" key={index} />
          ))}
        />
        <Route
          path={"seminar"}
          element={pageData?.events?.map((event, index) => (
            <EventListing event={event} filter="seminar" key={index} />
          ))}
        />
      </Routes>
      </div>
    </main>
  );
};

export default Events;
