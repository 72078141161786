import { useEffect, useState } from "react";

const usePixelRatio = () => {
    const [pixelRatio, setPixelRatio] = useState(0);
  
    useEffect(() => {
      const updatePosition = () => {
        setPixelRatio(Math.min(window.devicePixelRatio, 3));
      }
      updatePosition();
      
    }, [setPixelRatio]);
  
    return pixelRatio;
  };
  
  export default usePixelRatio;