import React from 'react';
import styles from "./logo.module.scss"
import { Player } from "@lottiefiles/react-lottie-player";
import useWindowSize from "../../hooks/useWindowSize";
import  {Link, useLocation} from "react-router-dom";

const Logo = (props) => {

    const { setPlayer, changeMenuMode, toggleMenuActive, menuButtonsActive} = props;
    const size = useWindowSize();
    const location = useLocation();

    const handleClick = () => {
        if (menuButtonsActive || location.pathname === "/") {
            toggleMenuActive()
        }
        changeMenuMode("main");
    }

    if (size.width > 1000) {
        return (
            <Link to="/" onClick={handleClick}>
            <div className={styles.main}>
                <Player
                lottieRef={(instance) => {
                    setPlayer({ lottie: instance });
                }}
                src="https://assets6.lottiefiles.com/packages/lf20_rrtrsz4n.json"
                autoplay={false}
                loop={false}
                keepLastFrame={true}
                style={{ height: "100%"}}
                />
          </div>
          </Link>
        );
    } else {
        return (
            <Link to="/" style={{textDecoration: "none"}} onClick={handleClick}>
            <div className={styles.main}>
                <h1>CCE</h1>
          </div>
          </Link>
        );
    }
    
};

export default Logo;