import React from "react";
import styles from "./button.module.scss";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const LinkButton = (props) => {
  const { link, text, clickFunction, isTopNav, forceActive } = props;

  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        classNames(styles.main, {
          [styles.active]: isActive || forceActive,
          [styles.top_nav]: isTopNav,
        })
      }
      onClick={clickFunction ? clickFunction : null}
    >
      <div>{text}</div>
      <div className={styles.underline}></div>
    </NavLink>
  );
};

export default LinkButton;
