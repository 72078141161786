import sanityClient from "@sanity/client";

const options = {
  projectId: "2kyp38wj", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: '2022-06-21',
  useCdn: true
}

const client = sanityClient(options)

export const previewClient = sanityClient({
  projectId: "2kyp38wj", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: '2022-06-21',
  token: '',
  useCdn: false,
});

export default client;