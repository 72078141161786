import React, { Fragment, useState, useRef } from "react";
import classNames from "classnames";
import FunctionButton from "./FunctionButton";
import ExternalLinkButton from "./ExternalLinkButton";

import styles from "./nav-info-tab.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import useWindowSize from "../../hooks/useWindowSize";

const NavInfoTab = (props) => {
  const { menuMode, data } = props;
  const size = useWindowSize();
  const [emailInput, setEmailInput] = useState("");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.current.submit();
  };

  return (
    <Fragment>
      <AnimatePresence exitBeforeEnter>
        {menuMode === "subscribe" && (
          <motion.div
            initial={size.width > 834 ? { y: "-100%" } : { x: "-100%" }}
            animate={size.width > 834 ? { y: "0%" } : { x: "0%" }}
            exit={size.width > 834 ? { y: "-100%" } : { x: "-100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className={classNames(styles.main, styles.subscribe)}
          >
            <div className={styles.subscribe_content}>
              <div className={styles.subscribe_text_container}>
                <p>{data.subscribe}</p>
              </div>
             
                  <div className={styles.subscribe_form_container}>
                    <form
                      ref={form}
                      id="mc-embedded-subscribe-form"
                      action="https://gmail.us12.list-manage.com/subscribe/post?u=378080bbbb79357a00b8cbfef&amp;id=e9fb1111c2" 
                      method="post" 
                      name="mc-embedded-subscribe-form" 
                      className="validate" 
                      target="_blank" 
                      rel="noopener norefferer"
                    >
                      <div className={styles.subscribe_email_input}>
                        <input
                          type="email"
                          className="form-control"
                          aria-label="Email address"
                          aria-describedby="emailHelp"
                          placeholder="Your email"
                          name="EMAIL"
                          value={emailInput}
                          onChange={(e) => setEmailInput(e.target.value)}
                        />
                      </div>
                      <div className={styles.subscribe_sign_up_button}>
                        <FunctionButton
                          text="Sign up"
                          clickFunction={handleSubmit}
                          isTopNav
                        />
                      </div>
                    </form>
       
                  </div>
      
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {menuMode === "contact" && (
          <motion.div
            initial={size.width > 834 ? { y: "-100%" } : { x: "-100%" }}
            animate={size.width > 834 ? { y: "0%" } : { x: "0%" }}
            exit={size.width > 834 ? { y: "-100%" } : { x: "-100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className={classNames(styles.main, styles.contact)}
          >
            <div className={styles.contact_content}>
              <div className={styles.contact_address}>
                <p>{data.emailCopy}</p>
                {/* <PortableText
                  value={data.address}
                  components={portableTextComponents}
                /> */}
              </div>

              <div className={styles.contact_email}>
                <ExternalLinkButton
                  link={`mailto:${data.email}`}
                  alwaysHighlight={size.width < 834}
                  text={data.email}
                  forceNoBlank={true}
                  isCompact
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
};

export default NavInfoTab;
