import classNames from "classnames";
import React from "react";
import styles from "./button.module.scss";

const FunctionButton = (props) => {
  const { text, active, clickFunction, isTopNav } = props;

  return (
    <button
      className={classNames(styles.main, {
        [styles.top_nav]: isTopNav,
        [styles.active]: active,
      })}
      onClick={clickFunction ? clickFunction : null}
    >
      <div>{text}</div>
      <div className={styles.underline}></div>
    </button>
  );
};

export default FunctionButton;
