import React, { useState, Fragment } from "react";
import styles from "./top-nav.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import useWindowSize from "../../hooks/useWindowSize";
import LinkButton from "./LinkButton";
import FunctionButton from "./FunctionButton";
import Logo from "./Logo";
import NavInfoTab from "./NavInfoTab";
import BurgerButton from "./BurgerButton";

const TopNav = (props) => {
  const [menuButtonsActive, setMenuButtonsActive] = useState(false);
  const [menuMode, setMenuMode] = useState("main");
  const [player, setPlayer] = useState({ lottie: null });
  const size = useWindowSize();

  const { data } = props;

  const toggleMenuActive = () => {
    if (menuButtonsActive) {
      setMenuButtonsActive(false);
      if (player.lottie) {
        if (size.width > 834) {
          player.lottie.playSegments([74, 168], true);
        } else {
          player.lottie.playSegments([23, 0], true);
        }
      }
    } else {
      setMenuButtonsActive(true);
      if (player.lottie) {
        if (size.width > 834) {
          player.lottie.playSegments([0, 74], true);
        } else {
          player.lottie.playSegments([0, 23], true);
        }
      }
    }
    setMenuMode("main");
  };

  const changeMenuMode = (buttonClicked) => {
    if (buttonClicked !== menuMode) {
      setMenuMode(buttonClicked);
      if (size.width <= 834 && player.lottie) {
        if (buttonClicked === "main") {
          player.lottie.playSegments([45, 23], true);
        } else {
          player.lottie.playSegments([23, 45], true);
        }
      }
    } else {
      setMenuMode("main");
    }
  };

  return (
    <Fragment>
      <NavInfoTab menuMode={menuMode} data={data} />
      <nav className={styles.main}>
        <Logo
          setPlayer={setPlayer}
          changeMenuMode={changeMenuMode}
          toggleMenuActive={toggleMenuActive}
          menuButtonsActive={menuButtonsActive}
        />
        {size.width > 834 ? (
          <motion.div
            initial={{ x: "0" }}
            animate={menuButtonsActive ? { x: 0 } : { x: "calc(100% - 60px)" }}
            transition={{ duration: 1.1, ease: "easeInOut" }}
            className={styles.pageNav}
          >
            <FunctionButton
              text="Menu:"
              active={false}
              clickFunction={toggleMenuActive}
              isTopNav
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={menuButtonsActive ? { opacity: 1 } : { opacity: 0 }}
              transition={{ duration: 0.4 }}
              className={styles.menuButtons}
            >
              <LinkButton
                text="About"
                link="/about"
                clickFunction={() => {
                  changeMenuMode("main");
                }}
                isTopNav
              />
              <LinkButton
                text="Board"
                link="/board"
                clickFunction={() => {
                  changeMenuMode("main");
                }}
                isTopNav
              />
              <LinkButton
                text="Events"
                link="/events"
                clickFunction={() => {
                  changeMenuMode("main");
                }}
                isTopNav
              />
              {/* <LinkButton
                text="PhD Award"
                link="/phd-award"
                clickFunction={() => {
                  changeMenuMode("main");
                }}
                isTopNav
              /> */}
              <FunctionButton
                text="Subscribe"
                active={menuMode === "subscribe"}
                clickFunction={() => {
                  changeMenuMode("subscribe");
                }}
                isTopNav
              />
              <FunctionButton
                text="Contact"
                active={menuMode === "contact"}
                clickFunction={() => {
                  changeMenuMode("contact");
                }}
                isTopNav
              />
            </motion.div>
          </motion.div>
        ) : (
          <Fragment>
            <AnimatePresence>
              {menuButtonsActive && (
                <motion.div
                  initial={{ y: "-100%" }}
                  animate={{ y: "0%" }}
                  exit={{ y: "-100%" }}
                  transition={{ duration: 0.4 }}
                  className={styles.mobileMenuButtons}
                >
                  <LinkButton
                    text="About"
                    link="/about"
                    clickFunction={toggleMenuActive}
                    isTopNav
                  />
                  <LinkButton
                    text="Board"
                    link="/board"
                    clickFunction={toggleMenuActive}
                    isTopNav
                  />
                  <LinkButton
                    text="Events"
                    link="/events"
                    clickFunction={toggleMenuActive}
                    isTopNav
                  />
                  {/* <LinkButton
                    text="PhD Award"
                    link="/phd-award"
                    clickFunction={toggleMenuActive}
                    isTopNav
                  /> */}
                  <FunctionButton
                    text="Subscribe"
                    active={menuMode === "subscribe"}
                    clickFunction={() => {
                      changeMenuMode("subscribe");
                    }}
                    isTopNav
                  />
                  <FunctionButton
                    text="Contact"
                    active={menuMode === "contact"}
                    clickFunction={() => {
                      changeMenuMode("contact");
                    }}
                    isTopNav
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <BurgerButton
              toggleMenu={toggleMenuActive}
              setPlayer={setPlayer}
              menuButtonsActive={menuButtonsActive}
              changeMenuMode={changeMenuMode}
              menuMode={menuMode}
            />
          </Fragment>
        )}
      </nav>
    </Fragment>
  );
};

export default TopNav;
